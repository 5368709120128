export default {
  comparator: [
    { value: '!=null', label: 't_exist_' },
    { value: '==null', label: 't_unexist_' },
    { value: '==', label: 't_is-equal_' },
    { value: '!=', label: 't_is-different_' }
  ],
  operations: null,
  dialog: false
}
