// Component states
import states from './state-condition-editor.state.js'

// Components
import MdiDelete from 'vue-material-design-icons/Delete.vue'

// Services
import { globalstates } from '@/services/states/states.service'

// Config file
import shortcuts from '@/assets/config/shortcuts/shortcuts.conf'

// Library
import {
  map as _map,
  get as _get,
  keyBy as _keyBy,
  remove as _remove,
  uniqBy as _uniqBy,
  forEach as _forEach,
  cloneDeep as _cloneDeep,
  flattenDeep as _flattenDeep
} from 'lodash-es'

/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'State-condition-editor'

// Vue@data
const data = function () {
  return {
    states,
    shortcuts
  }
}
// Vue@data

// Vue@subComponents
const components = {
  MdiDelete
}
// Vue@subComponents

// Vue@Properties
const props = {
  mjml: Object,
  conf: Object
}
// Vue@Properties

// Methods
const methods = {

  /**
   * Switch opérator and to or and vice versa
   */
  switchOperator () {
    this.state.default_operator = this.isOR ? 'and' : 'or'
  },

  // Func@getHumanizedCondition
  /**
   * Get conditions and humanize it
   * @param  {String} id (condition id)
   * @param  {Int} index (operand index)
   *
   * @return {String}    (humanized condition)
   */
  getHumanizedCondition (id, index) {
    const mapping = {}
    const cond = this.state.conditions[id]
    const fo = cond.firstOperand.match(/{{MM:([a-z_A-Z0-9]+)(?:\u007C[A-z]*)?}}/)[1]
    const defaultOperator = this.isOR ? this.$t('t_AND_') : this.$t('t_OR_')
    this.states.comparator.forEach(operator => {
      mapping[operator.value] = operator.label
    })

    switch (cond.type) {
      case 'comparison':
        return index === 0
          ? `<span class="operand">${fo}</span> ${this.$t(mapping[cond.operator])} ${cond.secondOperand}`
          : `${defaultOperator} <span class="operand">${fo}</span> ${this.$t(mapping[cond.operator])} ${cond.secondOperand}`
      case 'boolean':
        return index === 0
          ? `<span class="operand">${fo}</span> ${this.$t(mapping[cond.operator + cond.secondOperand])}`
          : `${defaultOperator} <span class="operand">${fo}</span> ${this.$t(mapping[cond.operator + cond.secondOperand])}`
    }
  },
  // Func@getHumanizedCondition

  // Func@querySearch
  /**
   * Search for keyword proposition
   * @param  {String}   queryString (input value)
   * @param  {Function} cb          (callback)
   */
  querySearch (queryString, cb) {
    const results = queryString
      ? globalstates.dynamicKeywordList
        .filter(keyword => {
          const key = keyword.value || keyword
          return key.includes(queryString.toUpperCase())
        })
      : globalstates.dynamicKeywordList
    cb(results)
  },
  // Func@querySearch

  // Func@openModal
  /**
   * Prepare operations for edition and
   * open element ui modal (append to body)
   */
  openModal () {
    this.states.operations = _map(this.state.operations, conditions => {
      return _map(conditions, condId => {
        const condition = _cloneDeep(this.state.conditions[condId])
        condition.firstOperand = condition.firstOperand.match(/{{MM:([_a-zA-Z0-9]+)(?:\u007C[A-z]*)?}}/)[1]
        switch (condition.type) {
          case 'boolean':
            return {
              type: 'boolean',
              operator: this.state.conditions[condId].operator === '==' ? '==null' : '!=null',
              firstOperand: condition.firstOperand,
              secondOperand: null
            }
          case 'comparison':
            return {
              type: 'comparison',
              operator: this.state.conditions[condId].operator,
              firstOperand: condition.firstOperand,
              secondOperand: this.state.conditions[condId].secondOperand
            }
        }
      })
    })

    if (!this.states.operations.length) {
      this.states.operations = [[
        {
          type: 'boolean',
          operator: '!=null',
          firstOperand: '',
          secondOperand: null
        }
      ]]
    }
    this.$set(states, 'dialog', true)
  },
  // Func@openModal

  // Func@saveAndCloseModal
  /**
   * Save edited conditions and close the
   * modal box
   */
  saveAndCloseModal () {
    const rawOperations = _cloneDeep(this.states.operations)
    const rawConditions = _flattenDeep(rawOperations)

    _forEach(rawConditions, cond => {
      const notValid = !cond.firstOperand.trim() || (cond.type === 'comparison' && !cond.secondOperand.trim())
      if (notValid) return
      if (cond.type === 'boolean') {
        cond.secondOperand = null
        cond.operator = cond.operator === '!=null' ? '!=' : '=='
      }

      const foForId = cond.firstOperand
        .toString()
        .trim()
        .toLowerCase()
      const soForId = cond.secondOperand === null
        ? cond.secondOperand
        : cond.secondOperand
          .toString()
          .trim()
          .toLowerCase()
      const foTag = cond.firstOperand
        .toString()
        .match(/{{MM:([-!@#_$%^&*(),.?":;{}|<>a-zA-Z0-9]+)}}/)

      cond.id = `cond_${foForId}_${cond.operator}_${soForId}_`

      if (_get(foTag, '1')) {
        cond.firstOperand = `{{MM:${foTag[1]
          .replace(/[-!@#$%^&*(),.?":;{}|<>]/g, '')
          .toUpperCase()}}}`
        return
      }

      cond.firstOperand = `{{MM:${cond.firstOperand
        .toString()
        .replace(/[-!@#$%^&*(),.?":;{}|<>]/g, '')
        .trim()
        .toUpperCase()}}}`
    })

    const factorizedCondition = _keyBy(_uniqBy(_remove(rawConditions, cond => cond.id), 'id'), 'id')
    const factorizedOperation = _map(rawOperations, conditions => {
      const andList = _map(_remove(conditions, cond => cond.id), 'id')
      if (andList.length) return andList
    })

    this.state.conditions = factorizedCondition
    this.state.operations = _remove(factorizedOperation)
    this.$set(states, 'dialog', false)
  },
  // Func@saveAndCloseModal

  // Func@addNewCondition
  /**
   * Add new condition line or group
   * @param  {Array}   list  (AND or OR list)
   * @param  {String}  index ('AND' or 'OR')
   */
  addNewCondition (list, type) {
    const condition = {
      type: 'boolean',
      operator: '!=null',
      firstOperand: '',
      secondOperand: null
    }

    switch (type) {
      case 'AND':
        list.push(condition)
        break
      case 'OR':
        list.push([condition])
        break
    }
  },
  // Func@addNewCondition

  // Func@deleteCondition
  /**
   * Delete AND or OR condition
   * @param  {Array}   list  (AND or OR list)
   * @param  {Integer} index (Line to delete)
   */
  deleteCondition (list, index) {
    list.splice(index, 1)
  },
  // Func@deleteCondition

  // Func@updateCondition
  /**
   * Update condition type depending selected operator
   * @param  {Object} condition (Condition)
   */
  updateCondition (condition) {
    const isBoolean = condition.operator === '!=null' || condition.operator === '==null'
    condition.type = isBoolean ? 'boolean' : 'comparison'
    if (isBoolean) condition.secondOperand = null
  }
  // Func@updateCondition
}

// Methods
const computed = {

  /**
   * Check if is OR default operator
   * @return {Boolean}
   */
  isOR () {
    return this.state.default_operator === 'or'
  },

  /**
   * Check if is AND default operator
   * @return {Boolean}
   */
  isAND () {
    return this.state.default_operator === 'and'
  },

  // Func@state
  /**
   * Get the current state
   * @return {Object} (current state)
   */
  state () {
    const states = this.mjml.attributes.states
    if (!states[this.conf.stateIndex]) {
      states.push({ default_operator: 'or', conditions: {}, operations: [] })
    }
    return this.mjml.attributes.states[this.conf.stateIndex]
  }
  // Func@state
}

// Vue component syntax
export default {
  name,
  data,
  methods,
  components,
  computed,
  props
}
